import React, { useState } from "react";
// eslint-disable-next-line
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Separator from '../theme/global/images/separator.png';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';




export default function Contact(props) {
    const [formData, setFormData] = useState({});

    const gaEventTracker = useAnalyticsEventTracker('Contact form');

// REACT MODAL STARTS

Modal.setAppElement('#root');

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }


  const [modalIsOpenDecline, setDeclineIsOpen] = React.useState(false);

  function openDeclineModal() {
    setDeclineIsOpen(true);
  }



  function closeDeclineModal() {
    setDeclineIsOpen(false);
  }



  const [modalIsOpenError, setErrorIsOpen] = React.useState(false);

  function openErrorModal() {
    setErrorIsOpen(true);
  }

  function afterOpenErrorModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeErrorModal() {
    setErrorIsOpen(false);
  }


// REACT MODAL ENDS


    function attendeeAmount() {
        if (document.getElementById("participant_amount1").checked === true) {
            document.getElementById("participant_amount1").value ="1"
            console.log(formData);
    }
    else if (document.getElementById("participant_amount2").checked === true) {
            document.getElementById("participant_amount2").value ="2"
            console.log(formData);

    }
    else if (document.getElementById("participant_amount3").checked === true) {
            document.getElementById("participant_amount3").value ="3"
            console.log(formData);

    }
    else if (document.getElementById("participant_amount4").checked === true) {
            document.getElementById("participant_amount4").value ="4"
            console.log(formData);

    }
    else if (document.getElementById("participant_amount5").checked === true) {
            document.getElementById("participant_amount5").value ="5"
            console.log(formData);

    }
    }




// CLEARING FORM AFTER SUCCESS

    const clearForm = e => {
        document.getElementById("name").value = '';
        document.getElementById("message").value = '';
        document.getElementById("yes").checked = false;
        document.getElementById("no").checked = false;
        document.getElementById("participant_amount1").checked = false;
        document.getElementById("participant_amount2").checked = false;
        document.getElementById("participant_amount3").checked = false;
        document.getElementById("participant_amount4").checked = false;
        document.getElementById("participant_amount5").checked = false;
        setFormData();
        console.log(formData);
    };


    const handleInput = e => {
        const copyFormData = { ...formData };
        copyFormData[e.target.name] = e.target.value;

        if (e.target.name === 'attend' && e.target.value === 'no') {
            document.getElementById("participant_amount1").checked = false;
            document.getElementById("participant_amount2").checked = false;
            document.getElementById("participant_amount3").checked = false;
            document.getElementById("participant_amount4").checked = false;
            document.getElementById("participant_amount5").checked = false;
            document.getElementById("bed_yes").checked = false;
            document.getElementById("bed_no").checked = false;
            copyFormData['amount'] = '0';
            copyFormData['bedspace'] = 'no';
        }

        setFormData(copyFormData);
        console.log('handled input ' + e.target.value);
    };



    const sendData = async e => {
        e.preventDefault();
        
        const {name, attend, amount, bedspace, message} = formData
        console.log(formData)
        e.target.reset();
    
        document.getElementById("modal-overlay").classList.add("show");

        try {
            const response = await fetch(
                "https://v1.nocodeapi.com/myevent/google_sheets/HqxdIcacvsYkMflq?tabId=MyEvent", // XXX added to avoid form sending to production
                {
                    method: "post",
                    body: JSON.stringify([[name, attend, amount, bedspace, message]]),
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            );
            const json = await response.json();
            
                if (attend === "no") {
                   openDeclineModal(); 
                   clearForm();
                   console.log(attend);
                   document.getElementById("modal-overlay").classList.remove("show");
                   gaEventTracker('Submitted')
                }
                else {
                    console.log("Success:", JSON.stringify(json))
                    openModal();   
                    clearForm();
                    document.getElementById("modal-overlay").classList.remove("show");
                    gaEventTracker('Submitted')
                }

          
                
        } catch (error) {
            console.error("Error:", error);
                openErrorModal();
                document.getElementById("modal-overlay").classList.remove("show");
                gaEventTracker('Error')
        }
    };

    function attendeeFields() {
        if (document.getElementById("yes").checked === true) {
            document.getElementById("participants").classList.add("open");
            document.getElementById("bedspace").classList.add("open");
            document.getElementById("participant_amount1").required = true;
            document.getElementById("participant_amount2").required = true;
            document.getElementById("participant_amount3").required = true;
            document.getElementById("participant_amount4").required = true;
            document.getElementById("participant_amount5").required = true;
            document.getElementById("bed_yes").required = true;
            document.getElementById("bed_no").required = true;
        }
        else {
            document.getElementById("participants").classList.remove("open");
            document.getElementById("bedspace").classList.remove("open");
            document.getElementById("participant_amount1").required = false;
            document.getElementById("participant_amount2").required = false;
            document.getElementById("participant_amount3").required = false;
            document.getElementById("participant_amount4").required = false;
            document.getElementById("participant_amount5").required = false;
            document.getElementById("bed_yes").required = false;
            document.getElementById("bed_no").required = false;
        }
    }

    const { t, i18n } = useTranslation();

    return (
        <>
        <div className="myEvent myEvent-form tester tester2">
            
    <div>
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            closeTimeoutMS={500}
            style={{
                    overlay: {
                        backgroundColor: 'rgba(255, 255, 255, 0)'
                    },
                    content: {
                        top: '5%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -5%)',
                        width: '85%',
                        boxShadow: 'rgba(100, 100, 111, 0.4) 0px 7px 29px 0px',
                        borderRadius: '18px',
                    
                    },    
                }}
        >
            <h2><Trans>Form success title</Trans></h2>
            <p><Trans>Form success description</Trans><Trans>Welcome bride</Trans> un <Trans>Welcome groom</Trans>.</p>
            <button onClick={closeModal}><Trans>Close</Trans></button>
        </Modal>

         <Modal
            isOpen={modalIsOpenDecline}
            onRequestClose={closeDeclineModal}
            closeTimeoutMS={500}
            style={{
                    overlay: {
                        backgroundColor: 'rgba(255, 255, 255, 0)'
                    },
                    content: {
                        top: '5%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -5%)',
                        width: '85%',
                        boxShadow: 'rgba(100, 100, 111, 0.4) 0px 7px 29px 0px',
                        borderRadius: '18px',
                    
                    },    
                }}
        >
            <h2><Trans>Form success title</Trans></h2>
            <p><Trans>Form decline description</Trans><Trans>Welcome bride</Trans> un <Trans>Welcome groom</Trans>.</p>
            <button onClick={closeDeclineModal}><Trans>Close</Trans></button>
        </Modal>

        <Modal
            isOpen={modalIsOpenError}
            onRequestClose={closeErrorModal}
            onAfterOpen={afterOpenErrorModal}
            closeTimeoutMS={500}
            style={{
                    overlay: {
                        backgroundColor: 'rgba(255, 255, 255, 0)'
                    },
                    content: {
                        top: '5%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -5%)',
                        width: '85%',
                        boxShadow: 'rgba(100, 100, 111, 0.4) 0px 7px 29px 0px',
                        borderRadius: '18px',
                    
                    },    
                }}
        >
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}><Trans>Form error title</Trans></h2>
            <p><Trans>Form error description</Trans></p>
            <button onClick={closeErrorModal}><Trans>Close</Trans></button>
        </Modal>
    </div>

        <div className="register"> 
            <h2><Trans>Form section</Trans></h2>
            <p><Trans>Form text</Trans></p>
            <form id="contact" name="contact" required onSubmit={sendData} autoComplete="off">
        
                <label>
                    <input type="text" id="name" name="name" placeholder={t('Form name')} onChange={handleInput} required/>
                    <span>{t('Form name')}</span>
                </label>
              
                <label><Trans>Form participate</Trans></label>
                <div className="form_yesno">
                   
                    <div><label>
                        <input 
                            type="radio" 
                            id="yes" 
                            name="attend" 
                            value="yes"
                            className="yesno button"
                            onChange={handleInput}
                            onClick={attendeeFields}
                            required
                        />
                        <span>{t('Form participate yes')}</span>
                    </label></div>
                    <div><label>
                        <input 
                            type="radio" 
                            id="no" 
                            name="attend" 
                            value="no"
                            className="yesno"
                            onChange={handleInput}
                            onClick={attendeeFields}
                            required
                        />
                        <span>{t('Form participate no')}</span>
                    </label></div>                   
                </div>

                <div className="participant_amount hidden" id="participants">
                    <label><Trans>Form participants</Trans></label>
                    <div className="form_participants">
                    
                        <div><label>
                            <input 
                                type="radio" 
                                id="participant_amount1" 
                                name="amount" 
                                className="yesno"
                                onChange={handleInput}
                                onClick={attendeeAmount}
                            />
                            <span>1</span>
                        </label></div>
                        <div><label>
                            <input 
                                type="radio" 
                                id="participant_amount2" 
                                name="amount" 
                                className="yesno"
                                onChange={handleInput}
                                onClick={attendeeAmount}
                            />
                            <span>2</span>
                        </label></div>
                        <div><label>
                            <input 
                                type="radio" 
                                id="participant_amount3" 
                                name="amount" 
                                className="yesno"
                                onChange={handleInput}
                                onClick={attendeeAmount}
                            />
                            <span>3</span>
                        </label></div>
                        <div><label>
                            <input 
                                type="radio" 
                                id="participant_amount4" 
                                name="amount" 
                                className="yesno"
                                onChange={handleInput}
                                onClick={attendeeAmount}
                            />
                            <span>4</span>
                        </label></div>       
                        <div><label>
                            <input 
                                type="radio" 
                                id="participant_amount5" 
                                name="amount" 
                                className="yesno"
                                onChange={handleInput}
                                onClick={attendeeAmount}
                            />
                            <span>5</span>
                        </label></div>              
                    </div>
                </div>

                <div className="participant_amount hidden" id="bedspace">    
                    <label><Trans>Form bedspace</Trans></label>
                    <div className="form_yesno">
                    
                        <div><label>
                            <input 
                                type="radio" 
                                id="bed_yes" 
                                name="bedspace" 
                                value="yes"
                                className="yesno button"
                                onChange={handleInput}
                                onClick={attendeeFields}
                                
                            />
                            <span>{t('Yes')}</span>
                        </label></div>
                        <div><label>
                            <input 
                                type="radio" 
                                id="bed_no" 
                                name="bedspace" 
                                value="no"
                                className="yesno"
                                onChange={handleInput}
                                onClick={attendeeFields}
                                
                            />
                            <span>{t('No')}</span>
                        </label></div>                   
                    </div>
                </div>

                <label>
                    <textarea type="text" id="message" name="message" placeholder={t('Form other notes')} onChange={handleInput} />
                    <span>{t('Form other notes')}</span>
                </label>
                
               <button name="submit" type="submit">Nosūtīt</button>
         </form>  
        </div>
        <div className="page-separator"><img src={Separator} alt=""/></div>
        </div>

        </>
    );
}