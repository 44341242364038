//useAnalyticsEventTracker.jsx

import React from "react";
import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category="MyEvent - Main app") => {
  const eventTracker = (action = "MyEvent GA action", label = "MyEvent Label") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;
