import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import BridesMaid from '../userdata/bridesmaid.jpg';
import GroomsMaid from '../userdata/groomsmaid.jpg';
import Couple from '../userdata/couple.png';
import { Trans } from 'react-i18next';
import Separator from '../theme/global/images/separator.png';

const couplesPage = () => {
    return (
        <>
        <div className="couplesPage-container">
			<h1><Trans>Couples section</Trans></h1>
            <ScrollAnimation animateIn="fadeIn" duration='5' animateOnce>
            <ScrollAnimation animateIn="pulse" duration='3' animateOnce>
               
			<div className="heart">
                <div className="couple_photo">
                     <img className="image" src={Couple} alt="Couple"/>
                </div>
			</div>
            
            </ScrollAnimation>
            </ScrollAnimation>
         
           
            <ScrollAnimation animateIn="fadeInUp" animateOnce>
			    <p><Trans>Couple heading</Trans></p>
            </ScrollAnimation>
			<h2><Trans>Witnesses section</Trans></h2>
			<div className="witnesses">
            <ScrollAnimation animateIn="fadeInLeft" animateOnce>
				<div className='slider-container'>
                    <img className="image" src={BridesMaid} alt="bridesmaid"/>
					<p><Trans>Bridesmaid fullname</Trans></p>
				</div>
            </ScrollAnimation>
			<ScrollAnimation animateIn="fadeInRight" animateOnce>
            	<div className='slider-container'>
                    <img className="image" src={GroomsMaid} alt="groomsmaid"/>
					<p><Trans>Groomsmaid fullname</Trans></p>
				</div>
            </ScrollAnimation>
			</div>
           
			<div className="page-separator"><img src={Separator} alt=""/></div>
		</div>



        </>
    )
}

export default couplesPage




