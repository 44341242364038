import React from 'react'
import { Trans } from 'react-i18next';
import planeIcon from '../theme/global/images/airplane.svg';
import euroIcon from '../theme/global/images/euro.svg';
import giftIcon from '../theme/global/images/gift.svg';
import Separator from '../theme/global/images/separator.png';


const presentsPage = () => {
    return (
        <>
          
            <div className="myEvent couplespage-container tester">
                <h2><Trans>Presents section</Trans></h2>
                <div className='presents'>
                    <p><Trans>Presents text</Trans></p><br/>
                    <br/>
                    <img width="50px" src={giftIcon} alt=""/>
                    <img width="50px" src={euroIcon} alt=""/>
                    <img width="50px" src={planeIcon} alt=""/>
                  
                </div>
             
                
                <div className="page-separator"><img src={Separator} alt=""/></div>
            </div>
        </>
    )
}

export default presentsPage




