export const schedules = [
  {
    id: 1,
    icon: 'rings',
    title: 'Laulību ceremonija Cēsīs, ',
    time: '13:00',
    description: '',
    url: 'https://www.waze.to/hud1yn1hnu',
    place: '"Villa Santa" - Tilta māja'
  },
  {
    id: 2,
    icon: 'champagne',
    title: 'Ataukstēts bokāls un torte, fotogrāfēšanās',
    time: '13:30',
    description: '',
    url: ''
  },
  {
    id: 3,
    icon: 'car2',
    title: 'Viesu ierašanās',
    time: '17:00',
    url: 'https://waze.to/hud1zy7tmp',
    place: 'viesu namā "Lantus"'
  },
  {
    id: 4,
    icon: 'couple2',
    title: 'Jaunā pāra ierašanās viesu namā',
    time: '19:00',
    description: ''
  },
    {
    id: 5,
    icon: 'cutlery',
    title: 'Pirmais tosts un vakariņas',
    time: '19:30',
    description: ''
  },
  {
    id: 6,
    icon: 'dance_shoe',
    title: 'Pirmā deja',
    time: '21:00',
    description: ''
  },
  {
    id: 7,
    icon: 'jam',
    title: 'Jam session',
    time: '21:30',
    description: ''
  },
    {
    id: 8,
    icon: 'hearts',
    title: 'Pusnakts mistērija',
    time: '24:00',
    description: ''
  },
];