import React from 'react';
import Countdown from 'react-countdown';
import i18next from 'i18next';
import Rings from '../theme/global/icons/rings.svg';


 
const Completionist = () => <div className="countdown-end"><img className="countdown-pulse" width="50px" src={Rings} alt="" /></div>;



const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    // Render a countdown
    return (
    <>
      <div className="days">{days}<span>{i18next.t('days')}</span></div>
      <div className="hours">{hours}<span>{i18next.t('hours')}</span></div>
      <div className="minutes">{minutes}<span>{i18next.t('minutes')}</span></div>
      <div className="seconds">{seconds}<span>{i18next.t('seconds')}</span></div>
    </>
    
    );
  }
};

class countdown extends React.Component {
    render() {
      const EndDate = i18next.t('Event date');      
      return (
        <Countdown 
          date={EndDate} 
          renderer={renderer}
        />
      );
    }
  }
  export default countdown;