import React from 'react';
import SignupForm from './components/signupForm';
import MainPage from './components/mainPage';
import CouplesPage from './components/couplesPage';
import PresentsPage from './components/presents';
import ScheduleList from './components/ScheduleList';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import ReactGA from 'react-ga';
import CustomInstall from './components/customInstall';
import useAnalyticsEventTracker from './components/useAnalyticsEventTracker';


import { withServiceWorkerUpdater } from '@3m1/service-worker-updater'
import ModalLoader from './components/modal-loader';





  


const TRACKING_ID = "UA-29997652-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


const App = (props) => {
  const gaEventTracker = useAnalyticsEventTracker('App Install');
  const {newServiceWorkerDetected, onLoadNewServiceWorkerAccept} = props;
  return newServiceWorkerDetected ? (
    <div className="app-update">
      <div>
        <h1>Mūsu lietotnei ir pieejama jauna versija</h1>
        <button 
          onClick={() => {
            onLoadNewServiceWorkerAccept();
            gaEventTracker('Updated')
          }}>
            Atjaunot
        </button>
      </div>
    </div>
  ) : 
        <DeviceOrientation lockOrientation={'portrait'}>
          {/* Will only be in DOM in landscape */} 
          <Orientation orientation='portrait' alwaysRender={false}>
            <div className="myEvent-container">
              <ModalLoader />
              <CustomInstall />
              <MainPage />
              <CouplesPage />
              <ScheduleList />
              <PresentsPage />
              <SignupForm />
            </div>
          </Orientation>
          
          {/* Will stay in DOM, but is only visible in portrait */}
          <Orientation orientation='landscape' alwaysRender={false}>
             <div className="device-disclaimer">
              <div>Aplūko šo lietotni uz sava telefona vai sagriez ierīci vertikāli.</div>
            </div>
          </Orientation>
        </DeviceOrientation>
}

export default withServiceWorkerUpdater(App)