import React from "react";
import ReactDOM from 'react-dom';
import './theme/global/css/App.css';
import './index.css';
import App from './App';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater'
import useAnalyticsEventTracker from './components/useAnalyticsEventTracker';

// Render the App
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: onServiceWorkerUpdate
});



// CUSTOM INSTALL STARTS HERE
// https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Add_to_home_screen



let deferredPrompt;

const addBtn = document.querySelector('.customInstall');
const installBtn = document.querySelector('.install-button');


window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI to notify the user they can add to home screen
  //  addBtn.style.display = 'block';
  setTimeout(() => {  addBtn.classList.add("show"); }, 7000);



  

  installBtn.addEventListener('click', (e) => {
    // hide our user interface that shows our A2HS button
    addBtn.classList.remove("show");

    // Show the prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
    // GOOGLE ANALYTICS TRACKING
    const gaEventTracker = useAnalyticsEventTracker('App install');
    
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
          addBtn.classList.remove("show");
          gaEventTracker('Installed')
        } else {
          console.log('User dismissed the A2HS prompt');
          addBtn.classList.remove("show");
          gaEventTracker('Declined')
        }
        deferredPrompt = null;
      });
  });
});
// CUSTOM INSTALL ENDS HERE