import React from 'react';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function ModalLoader() {
  return (
    <div id="modal-overlay" className="modal-overlay">
        <Loader
            type="MutatingDots"
            color="coral"
            secondaryColor="gold"
            height={100}
            width={100}
        />
    </div>
  );
}

export default ModalLoader


