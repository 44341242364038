import React, { useEffect, useState } from 'react';
import {schedules} from '../userdata/locales/schedules.js';
import Separator from '../theme/global/images/separator.png';
import Schedule from './schedule.js';
import { Trans } from 'react-i18next';

export default function ScheduleList() {
  const [times, setTimes] = useState([]);

  useEffect(() => {
    const timeList = schedules.map(({ time }) => new Date(`${new Date().toLocaleDateString('en-US')} ${time}`));

    setTimes(timeList);
  }, [schedules]);

  return (
    <div className="schedule-container">
			<h1><Trans>Schedule section</Trans></h1>
     
    <section className="schedule-list">
      {schedules.map((schedule) => {
        
        return (

            <Schedule timeList={ times } key={schedule.id} {...schedule}></Schedule>
        
        );
      })}
    </section>
    <div className="page-separator"><img src={Separator} alt="separator"/></div>
    </div>
  );
}
