import React from 'react';
import CountDown from './countDown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Separator from '../theme/global/images/separator.png';



export default function MainPage(props) {

const { t, i18n } = useTranslation();  


const eventDateGlobal = i18next.t('Event date');

const eventDate = new Date(eventDateGlobal);

    
var monthNames = i18next.t('month names', { returnObjects: true });
var monthName=monthNames[eventDate.getMonth()];


var  dayNames = i18next.t('day names', { returnObjects: true });
var dayName=dayNames[eventDate.getDay()];


var eventYear = eventDate.getFullYear();
var myeventDate = eventDate.getDate();


    return (
        <>
       <div className="mainPage-container">
	
			<div className="item intro">
                <p>{t('Welcome text')}</p>
				
            </div>
            <div className="item names">
				<div className="brides_name">{t('Welcome bride')}</div>
				<div className="and"><span>{t('Welcome and')}</span></div>
				<div className="grooms_name">{t('Welcome groom')}</div>
			</div>

            <div className="item date">
				<div className="dayname">{dayName}</div>
				<div className="day">{myeventDate}<span>{eventYear}</span></div>
				<div className="month">{monthName}</div>
			</div>
        
            <div className="item countdown">
				<CountDown />
			
			</div>
			<div className="separator"><img src={Separator} alt="" /></div>
		</div>



          


       
        </>

      
    );
}