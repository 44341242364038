import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

export default function Schedule({ id, icon, url, place, title, time }) {
	

	return (
		<ScrollAnimation animateIn="fadeInUp" animateOnce offset="350">
			<div className="schedule-grid">
				<div className="time">{time}</div>
				<div className="title">{title}<br/><a target="_blank" rel="noreferrer" href={url}>{place}</a></div>
				<div className={'icon ' + icon}></div>
				<div className="line"></div>
				<div className="dot"></div>
			</div>
		</ScrollAnimation>  
	);
}
