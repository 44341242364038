import React from "react";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import './customInstall.css';

export default function Contact(props) {

const closeBtn = document.querySelector('.customInstall');    

// GOOGLE ANALYTICS TRACKING
const gaEventTracker = useAnalyticsEventTracker('App Install');


function hideInstall() {
    document.querySelector('.customInstall').classList.remove("show");
    console.log('close button pressed');
    gaEventTracker('Maybe later')
}




return (
        <>
      
    <div className="modalContainer2 customInstall">
       <div className="modal">
            <header className="modal_header">
        
            <h2 className="modal_header-title">Instalē mūsu lietotni</h2>
           
         </header>
         <main className="modal_content"><p>Uzinstalē mūsu lietotni, lai Tev būtu daudz ērtāk to apskatīt atkārtoti.</p></main>
         <footer className="modal_footer">
        

           <button className="button secondaryButton" onClick={hideInstall}>Varbūt vēlāk</button>
           <button className="button install-button">Instalēt</button>  
         </footer>
       </div>
    </div>


    
        </>
    );
}



